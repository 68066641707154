
import { defineComponent } from 'vue';
import moment from 'moment';
import Accordian from '@/components/clickables/Accordian.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import CategoryService from '@/services/CategoryService';
import MedicineService from '@/services/MedicineService';
import InventoryService from '@/services/InventoryService';
import {
  Product,
  Drug,
  ProductData,
  ProductCompare,
  ProductFieldCompare,
  ProductPackageCompare,
} from '@/models/product';
import PriceFormatter from '@/mixins/PriceFormatter';

export default defineComponent({
  name: 'ProductUpdated',
  mixins: [PriceFormatter],
  components: {
    Accordian,
    Pagination,
    UiSearch,
    PreLoader,
  },
  data: () => ({
    size: 1 as number,
    total: 0 as number,
    currentPage: 1 as number,
    productList: [] as ProductData[],
    products: [] as Product[],
    drugs: [] as Drug[],
    lastUpdated: '' as string,
    productName: '' as string,
    isLoaded: false,
    comparedProducts: [] as ProductCompare[],
    isEmpty: false,
    searchTimeoutId: 0,
    isPageLoaded: false,
    packingTableHeader: [
      'Størrelse',
      'NVR',
      'Forbrugerpris inkl. moms',
      'Udleveringsbestemmelse',
    ],
    notFound: [] as boolean[],
    deliveryregulationCategories: [],
  }),
  async created(): Promise<void> {
    await this.getProducts();
  },
  computed: {
    currentItems(): number {
      const currentTotal = 10 * this.currentPage;
      return currentTotal >= this.total ? this.total : currentTotal;
    },
  },
  watch: {
    async productName(val) {
      if (!/\S/.test(val) && val !== '') {
        return;
      }

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        this.isEmpty = false;
        this.currentPage = 1;
        this.getProducts();
      }, 300);
    },
  },
  async mounted() {
    const response = await MedicineService.lastSyncedAt();

    this.lastUpdated = moment(response?.last_synced_at).format('DD.MM.YY');

    this.deliveryregulationCategories = await CategoryService.deliveryRegulation({ take: 50 });
  },
  methods: {
    getProductCaption(model?: any) {
      const strength = model?.medicine_details?.strength?.text;

      return `${model?.medicine_details?.dosage_form?.text} ${strength ? `(${strength})` : ''}`;
    },
    scrollBack(): void {
      const source = this.$refs['product-updated-anchor'] as HTMLElement;
      const top = source.offsetTop - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    },
    async getProducts(): Promise<void> {
      this.isLoaded = false;
      this.productList = [] as ProductData[];
      this.notFound = [] as boolean[];
      this.total = 0;

      const service = await MedicineService.listProducts(
        'pendingupdate',
        this.productName,
        this.currentPage,
      );
      if (service.data.length > 0) {
        this.productList = service.data;
        this.size = Math.ceil(service.total / service.per_page);
        this.total = service.total;
        this.isEmpty = false;
        this.productList.forEach(() => this.notFound.push(false));
      } else {
        this.isEmpty = true;
      }

      this.isLoaded = true;
      this.isPageLoaded = true;
    },
    onNameChange(): void {
      this.getProducts();
    },
    async isAccordianOpened(id: string, index: number): Promise<void> {
      if (!this.getProduct(id)) {
        try {
          const product = await MedicineService.getProduct(id);
          product.id = id;
          const drug: Drug = await InventoryService.drug(product.code);
          if (!this.getDrug(product.code)) {
            const isProductExists = this.products.filter((prod) => (
              prod.id === product.id
            )).length > 0;
            if (!isProductExists) {
              this.products.push(product);
            }
            const isDrugExists = this.drugs.filter((prod) => prod.id === drug.id).length > 0;
            if (!isDrugExists) {
              this.drugs.push(drug);
              this.checkUpdatedData(product, drug);
            }
          }
          this.notFound[index] = false;
        } catch (error) {
          this.notFound[index] = true;
        }
      }
    },
    getProduct(id: string): Product {
      const product = this.products.filter((prod) => prod.id === id)[0];
      return product;
    },
    checkIfRegDateSet(product: Product): boolean {
      const date = product.medicine_details.de_registration_date;
      return date !== '' && date !== null;
    },
    getComparedProduct(id: string): ProductCompare {
      const comparedProduct = this.comparedProducts.filter((prod) => prod.id === id)[0];
      return comparedProduct;
    },
    getDrug(code: string): Drug {
      const drug = this.drugs.filter((drg) => drg.code === code)[0];
      return drug;
    },
    paginationHandler(): void {
      this.getProducts();
    },
    formatDate(date: number): string {
      const newDate = moment(date).format('DD[.]MM[.]YY');

      return newDate;
    },
    onHoverMessage(date: number): string {
      return `Udgået ${
        this.formatDate(date)
      }. Lægemidlet kan være helt udgået eller midlertidigt udgået pga. leveringssvigt.`;
    },
    async checkUpdatedData(product: Product, drug: Drug) {
      const productFieldCompare: ProductFieldCompare = {
        current: '',
        new: '',
        updated: false,
      };
      const productCompared: ProductCompare = {
        id: '',
        name: { ...productFieldCompare },
        strength: { ...productFieldCompare },
        dosage_form: { ...productFieldCompare },
        routes: { ...productFieldCompare },
        atc: { ...productFieldCompare },
        packages: [] as ProductPackageCompare[],
      } as ProductCompare;

      if (product.code === drug.code) {
        productCompared.id = product.id;

        // Check Product Name
        productCompared.name.current = drug.title;

        if (product.name !== drug.title) {
          productCompared.name.new = product.name;
          productCompared.name.updated = true;
        }

        // Check Product Strength
        productCompared.strength.current = drug.details.strength.text;

        if (product.medicine_details.strength.text !== drug.details.strength.text) {
          productCompared.strength.new = product.medicine_details.strength.text;
          productCompared.strength.updated = true;
        }

        // Check Product Dosage Form
        productCompared.dosage_form.current = drug.details.dosage_form.text;

        if (
          product.medicine_details.dosage_form.code === drug.details.dosage_form.code
          && product.medicine_details.dosage_form.text !== drug.details.dosage_form.text
        ) {
          productCompared.dosage_form.new = product.medicine_details.dosage_form.text;
          productCompared.dosage_form.updated = true;
        }

        // Check Product Routes
        const pRoutes = product.medicine_details.routes.map((route) => route.text).join(', ');
        const dRoutes = drug.details.routes.map((route) => route.text).join(', ');

        productCompared.routes.current = dRoutes;

        if (pRoutes !== dRoutes) {
          productCompared.routes.new = pRoutes;
          productCompared.routes.updated = true;
        }

        // Check Product ATC
        const atc = drug.entity_categories.find((el) => el.category_type_code === 'atc');

        let atcResponse = {} as any;

        if (atc) {
          atcResponse = await CategoryService.atcShow((atc as any).category_id);
        }

        productCompared.atc.current = atcResponse?.code || 'Ikke givet';

        if (product.medicine_details.atc !== productCompared.atc.current) {
          productCompared.atc.new = product.medicine_details.atc;
          productCompared.atc.updated = true;
        }

        // Check Product Packages
        if (drug.units.length) {
          product.packages.forEach((pack) => {
            drug.units.forEach((unit) => {
              const packUp: ProductPackageCompare = {
                code: '',
                size: {
                  current: '',
                  new: '',
                  updated: false,
                },
                price: {
                  current: '',
                  new: '',
                  updated: false,
                },
                delivery_regulation: {
                  current: '',
                  new: '',
                  updated: false,
                },
                withdrawal_date: '',
                removed: false,
              };

              const {
                package_size: pSize,
                package_price: { consumer_price: pPrice },
                delivery_regulation: pDelReg,
                withdrawal_date: pWithDate,
              } = pack.package_details;

              const {
                package_size: dSize,
                package_prices: {
                  consumer_price: { amount: dPrice },
                },
                withdrawal_date: dWithDate,
              } = unit.details;

              const EntCat = unit.entity_categories.filter(
                (ect) => ect.category_type_code === 'deliveryregulation',
              )[0]?.category_id;

              let dDelReg = '';

              if (EntCat) {
                const pdrc = this.deliveryregulationCategories
                  .find((drc: any) => drc.id === EntCat) as any;
                dDelReg = pdrc.code;
              }

              const isNewPackageExists = productCompared.packages.filter(
                (pUp: ProductPackageCompare) => pUp.code === pack.code,
              ).length;
              const isOldPackageExists = productCompared.packages.filter(
                (pUp: ProductPackageCompare) => pUp.code === unit.code,
              ).length;

              if (pack.code === unit.code) {
                packUp.code = pack.code;

                // Check package delivery regulation
                packUp.delivery_regulation.current = dDelReg;

                if (pDelReg.code !== dDelReg) {
                  packUp.delivery_regulation.new = pDelReg.code;
                  packUp.delivery_regulation.updated = true;
                }

                // Check package size
                packUp.size.current = dSize.text;

                if (pSize.text !== dSize.text) {
                  packUp.size.new = pSize.text;
                  packUp.size.updated = true;
                }

                // Check package price
                packUp.price.current = dPrice.toString();

                if (pPrice !== dPrice.toString()) {
                  packUp.price.new = pPrice;
                  packUp.price.updated = true;
                }

                packUp.removed = pWithDate !== '' && pWithDate !== null;
                if (packUp.removed) {
                  packUp.withdrawal_date = pWithDate;
                }
                if (isNewPackageExists) {
                  productCompared.packages.forEach((pc, idx) => {
                    if (pc.code === pack.code) {
                      productCompared.packages.splice(idx, 1);
                    }
                  });
                }
                productCompared.packages.push(packUp);
              } else if (!isNewPackageExists) {
                packUp.code = pack.code;
                packUp.delivery_regulation.current = pDelReg.code;
                packUp.size.current = pSize.text;
                packUp.price.current = pPrice.toString();
                packUp.removed = pWithDate !== '' && pWithDate !== null;
                if (packUp.removed) {
                  packUp.withdrawal_date = pWithDate;
                }
                productCompared.packages.push(packUp);
              } else if (!isOldPackageExists) {
                packUp.code = unit.code;
                packUp.delivery_regulation.current = dDelReg;
                packUp.size.current = dSize.text;
                packUp.price.current = dPrice.toString();
                packUp.removed = dWithDate !== '' && dWithDate !== null;
                if (packUp.removed) {
                  packUp.withdrawal_date = dWithDate;
                }
                productCompared.packages.push(packUp);
              }
            });
          });
        } else {
          product.packages.forEach((pack) => {
            const packUp: ProductPackageCompare = {
              code: '',
              size: {
                current: '',
                new: '',
                updated: false,
              },
              price: {
                current: '',
                new: '',
                updated: false,
              },
              delivery_regulation: {
                current: '',
                new: '',
                updated: false,
              },
              withdrawal_date: '',
              removed: false,
            };
            const {
              package_size: pSize,
              package_price: { consumer_price: pPrice },
              // delivery_regulation: { code: pDelReg },
              withdrawal_date: pWithDate,
            } = pack.package_details;

            packUp.code = pack.code;
            // packUp.delivery_regulation = pDelReg;
            packUp.size.current = pSize.text;
            packUp.price.current = pPrice.toString();
            packUp.removed = pWithDate !== '' && pWithDate !== null;

            if (packUp.removed) {
              packUp.withdrawal_date = pWithDate;
            }

            productCompared.packages.push(packUp);
          });
        }
      }

      productCompared.packages.sort(
        (a: ProductPackageCompare, b: ProductPackageCompare) => (
          Number(a.removed) - Number(b.removed)
        ),
      );

      this.comparedProducts.push(productCompared);
    },
    editProduct(id: ''): void {
      this.$router.push({
        name: 'ProductUpdate',
        params: {
          drugId: id,
        },
      });
    },
  },
});
